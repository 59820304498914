import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { removeAccents } from '../utils/text-accent-util';

// Icons
import { IoIosArrowUp, IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';

const DocMenu = ({ id, title, img, data }) => {
  // const { menu, setMenu } = useContext(StoreContext);
  const [menu, setMenu] = useState({});

  const generateIds = (data) => {
    if (data) {
    function generateIdsItems(lastId, items) {
      if (items) {
        items.forEach((item) => {
          item.id = `/${lastId}${removeAccents(item.url)}`;
          item.isOpen = false;
          generateIdsItems(item.id, item.items);
        })
      }
    }

      Object.keys(data).forEach((key) => {
        data[key].id = key;
  
        // Pages
        data[key].pages.forEach((page) => {
          page.id = page.href;
          page.isOpen = true;
          generateIdsItems(page.id, page.tableOfContents?.items)
        });
      });
      console.log('IDS', data)
    }
  }

  const updateIsOpenById = (id, array = [], prop) => {
    // console.log('AAA', id, array);
    array.forEach(element => {
      if (element.id === id) {
        element.isOpen = !element.isOpen;
      };

      if (element.tableOfContents && element.tableOfContents[prop]) {
        updateIsOpenById(id, element.tableOfContents[prop], prop);
      } else if (element[prop]) {
        updateIsOpenById(id, element[prop], prop);
      }
    });
  }

  const handleMenuObject = (key) => {
    setMenu({
          ...menu,
          [key]: { ...menu[key], isOpen: !menu[key].isOpen },
        })
  }

  const handleMenu = (key, id, prop) => {
    // console.log('ANTES ', menu);
    updateIsOpenById(id, menu[key].pages, prop);
    // console.log('DESPUES', menu);
    setMenu({...menu});
  }

  useEffect(() => {
    generateIds(data);
    // console.log('---ENTROOO DocMenu useEffect', data);
    // console.log('menu', data);
    // console.log('menu2', JSON.stringify(data));
    // console.log('UPDATE ITEMSSSSSSSS', items);

    
    // window.scrollTo(0, 0);

    // const data2=[{id:'RAKUFNUBNY00UBZ40950',name:'Grade 1 Cover',activityId:'RAKUFNUBNY00UBZ40950',nodeType:'activity',suppressed:!1,hidden:!1},{children:[{id:'SLWDYEQHTZAFA3ALH195',name:'Build Background Video',activityId:'SLWDYEQHTZAFA3ALH195',nodeType:'activity',suppressed:!1,hidden:!1,assetReference:{referenceId:'UWFHA5A1E0EGKCM0W899',assetType:'image'}},{children:[{id:'HQUCD2SSRKMYC2PJM636',name:'Eat or Be Eaten Splash Card',activityId:'HQUCD2SSRKMYC2PJM636',nodeType:'activity',suppressed:!1,hidden:!0},{children:[{id:'ZDTWEZFL13L8516VY480',name:'Interactive Work Text: Eat or Be Eaten',activityId:'ZDTWEZFL13L8516VY480',nodeType:'activity',suppressed:!1,hidden:!0,defaultLaunchMode:'modal'}],}],}],}]

    const items = [
      {
        id:'aaa',
        "url":"#aaa",
        "title":"aaa",
        isOpen: true,
      },
      {
        id:'bbb',
        "url":"#bbb",
        "title":"bbb",
        isOpen: true,
        items: [
          {
            id:'ddd',
            "url":"#ddd",
            "title":"ddd",
            isOpen: true,
            items: [
              {
                id:'eee',
                "url":"#eee",
                "title":"eee",
                isOpen: true,
                items: [
                  {
                    id:'fff',
                    "url":"#fff",
                    "title":"fff",
                    isOpen: true,
                  },
                  {
                    id:'fff2',
                    "url":"#fff2",
                    "title":"fff2",
                    isOpen: true,
                  }
                ]
              },
              {
                id:'eee2',
                "url":"#eee2",
                "title":"eee2",
                isOpen: true,
              }
            ]
          },
          {
            id:'ddd2',
            "url":"#ddd2",
            "title":"ddd2",
            isOpen: true,
          }
        ]
      },
      {
        id:'ccc',
        "url":"#ccc",
        "title":"ccc",
        isOpen: true,
      },
    ];

    function findId(id, arr) {
      return arr.reduce((a, item) => {
        if (a) {
          return a;
        }
        if (item.id === id) {
          return item;
        }
        if (item.items) return findId(id, item.items);
      }, null);
    }
    setMenu(data);

  }, [data]);

  const generateSubMenu = (key, slug, items) => {
    if (items) {
      return (
        <ul key='aa' className="sidebar-sub-headers">
          {items?.map((item) => {
            return item.title ? (
              <li key={`sub_${item.title}`} className="sidebar-sub-header">
                <span
                  className=" inline-flex items-center"
                  onClick={() =>
                    handleMenu(key, item.id, 'items')
                  }
                >
                  <span 
                  className="text-center cursor-pointer" 
                  style={{width: '16px'}}
                  >
                    {item.items ? (item.isOpen ? <IoIosArrowDown /> : <IoIosArrowForward />) : '•'}</span>&nbsp;&nbsp;
                  <Link
                    // to={removeAccents(item.url)}
                    to={`/${slug}${removeAccents(item.url)}`}
                    // to={`/${slug.endsWith("/") ? slug.slice(0, -1) : slug}${removeAccents(item.url)}`}
                    // to={`/${menu.slug}`}
                    className="sidebar-link inline-flex items-center"
                    activeClassName="active"
                  >
                    {item.title}
                  </Link>
                </span>
                {item.isOpen && generateSubMenu(key, slug, item.items)}
              </li>
            ) : generateSubMenu(key, slug, item.items);
          })}
        </ul>
      );
    }
  };

  return (
    <div>
      {img && (
        <>
          <div className="img-logo mt-4 text-center">
            <Link to={`/${id}`}>
              <img className="h-24" src={img} alt={id} title={id} />
            </Link>
            <p className="text-gray-700 font-bold">{title}</p>
          </div>
          <hr />
        </>
      )}

      {/* <br/>
      <br/>
      {test()}
      <br/>
      <br/> */}

      {menu &&
        Object.keys(menu).map((item) => (
          <div key={`page_${item}`} className="my-6">
            <div
              className="flex justify-between font-bold border-b border-solid border-gray-300 cursor-pointer"
              onClick={() =>
                handleMenuObject(item)
              }
            >
              <span className="uppercase">{item}</span>
              <span className="m-2">
                {menu[item].isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </span>
            </div>
            <div className={menu[item].isOpen ? 'block' : 'hidden'}>
              <ul className="sidebar-links2">
                {menu[item].pages?.map((page) => page && (
                  <li
                    key={`page_${page.title}`}
                    className="sidebar-sub-header list-disc2 my-4"
                  >
                    <span 
                      className=" inline-flex items-center"
                      onClick={() =>
                        handleMenu(item, page.id, '')
                      }
                    >
                      <span 
                        className="text-center cursor-pointer" 
                        style={{width: '16px'}}
                      >
                        {Object.entries(page.tableOfContents).length ? (page.isOpen ? <IoIosArrowDown /> : <IoIosArrowForward />) : '•'}
                      </span>&nbsp;&nbsp;
                      <Link
                        to={`/${page.href}`}
                        className="sidebar-link"
                        activeClassName="active"
                      >
                        {page.title}
                      </Link>
                    </span>
                    {page.isOpen && generateSubMenu(item, page.href, page.tableOfContents?.items)}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
    </div>
  );
};

export default DocMenu;
