const accentsMap = {
  a: 'à|á|â|ã|ä|å|ấ|ắ|ẳ|ẵ|ặ|ầ|ằ|ȃ|ā|ă|ą|ǎ|ǻ|a̋|ȁ|a̧',
  A: 'À|Á|Â|Ã|Ä|Å|Ấ|Ắ|Ẳ|Ẵ|Ặ|Ầ|Ằ|Ȃ|Ā|Ă|Ą|Ǎ|Ǻ|A̋|Ȁ|A̧',
  e: 'ē|ĕ|ė|ę|ě|e̋|ȅ|ê̌|ȩ|ɛ̧|è|é|ê|ë|ế|ḗ|ề|ḕ|ḝ|ȇ',
  E: 'È|É|Ê|Ë|Ế|Ḗ|Ề|Ḕ|Ḝ|Ȇ|Ē|Ĕ|Ė|Ę|Ě|E̋|Ȅ|Ê̌|Ȩ|Ɛ̧',
  i: 'i̧|ɨ̧|ȉ|i̋|ǐ|ĩ|ī|ĭ|į|ı|ì|í|î|ï|ḯ|ȋ',
  I: 'Ì|Í|Î|Ï|Ḯ|Ȋ|I̧|Ɨ̧|Ȉ|I̋|Ǐ|Ĩ|Ī|Ĭ|Į|İ',
  o: 'ò|ó|ô|õ|ö|ø|ố|ṍ|ṓ|ȏ|ō|ŏ|ő|ơ|ǒ|ǿ|ồ|ṑ|ȍ|o̧',
  O: 'Ò|Ó|Ô|Õ|Ö|Ø|Ố|Ṍ|Ṓ|Ȏ|Ō|Ŏ|Ő|Ơ|Ǒ|Ǿ|Ồ|Ṑ|Ȍ|O̧',
  u: 'ù|ú|û|ü|ũ|ū|ŭ|ů|ű|ų|ȗ|ư|ǔ|ǖ|ǘ|ǚ|ǜ|ứ|ṹ|ừ|ȕ|u̧',
  U: 'Ù|Ú|Û|Ü|Ũ|Ū|Ŭ|Ů|Ű|Ų|Ȗ|Ư|Ǔ|Ǖ|Ǘ|Ǚ|Ǜ|Ứ|Ṹ|Ừ|Ȕ|U̧',

  n: 'ñ|ń|ņ|ň|ŉ|n̆|ǹ',
  N: 'Ñ|Ń|Ņ|Ň|N̆|Ǹ',

  b: 'b̌|b̧',
  B: 'B̌|B̧',
  c: 'ç|ḉ|ć|ĉ|ċ|č|c̆|č̣',
  C: 'Ç|Ḉ|Ć|Ĉ|Ċ|Č|C̆|Č̣',
  d: 'ð|ď|đ|ḑ',
  D: 'Ð|Ď|Đ|Ḑ',
  f: 'ƒ|f̌',
  F: 'F̌',
  g: 'ǧ|ĝ|ǵ|ğ|ġ|ģ',
  G: 'Ĝ|Ǵ|Ǧ|Ğ|Ġ|Ģ',
  h: 'ĥ|ħ|ḫ|ȟ|ḩ',
  H: 'Ĥ|Ħ|Ḫ|Ȟ|Ḩ',
  j: 'Ĵ|J̌',
  J: 'ĵ|ǰ',
  k: 'ǩ|ķ|ḱ|k̆|ќ',
  K: 'Ǩ|Ķ|Ḱ|K̆|Ќ',
  l: 'ĺ|ļ|ľ|ŀ|Ł|ł',
  L: 'Ĺ|Ļ|Ľ|Ŀ',
  m: 'm̌|ḿ|m̆|m̧',
  M: 'M̌|Ḿ|M̆|M̧',
  p: 'p̌|p̆|ṕ',
  P: 'P̌|P̆|Ṕ',
  q: 'q̌|q̧',
  Q: 'Q̌|Q̧',
  r: 'ř̩|ŕ|ŗ|ř|r̆|ȓ|ȑ',
  R: 'Ř̩|Ŕ|Ŗ|Ř|R̆|Ȓ|Ȑ',
  s: 'ṧ|ś|ŝ|ș|ş|š|ſ|ṥ',
  S: 'Ṧ|Ś|Ŝ|Ş|Ș|Š|Ṥ',
  t: 'ţ|ț|ť|ŧ|t̆',
  T: 'Ţ|Ț|Ť|Ŧ|T̆',
  v: 'v̌|v̆',
  V: 'V̌|V̆',
  w: 'w̌|ŵ|ẃ|ẁ',
  W: 'W̌|Ŵ|Ẃ|Ẁ',
  x: 'x̌|x̆|x́|x̧',
  X: 'X̌|X̆|X́|X̧',
  y: 'ỳ|y̌|ŷ|y̆|ý|ÿ',
  Y: 'Ỳ|Y̌|Ŷ|Ÿ|Y̆|Ý',
  z: 'z̧|ź|ż|ž',
  Z: 'Z̧|Ź|Ż|Ž',

  ae: 'æ|ǽ',
  AE: 'Æ|Ǽ',
  ij: 'ĳ',
  IJ: 'Ĳ',
  oe: 'œ',
  OE: 'Œ',
  th: 'þ',
  TH: 'Þ',
  г: 'ѓ',
  Г: 'Ѓ',
};

export const removeAccents = (text) =>
  Object.keys(accentsMap).reduce(
    (acc, el) => acc?.replace(new RegExp(accentsMap[el], 'g'), el),
    text,
  );
