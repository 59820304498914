import React, { useState, useEffect } from 'react';
import Sidebar from '../components/sidebar';
import './doc-layout.scss';

import { getDocById } from '../services/document.service';
import DocMenu from '../components/docMenu';

const DocLayout = ({ location, children, pages }) => {
  // console.log('location', location.pathname);
  // console.log('menu', menu);
  // console.log('pages', pages);
  const [data, setData] = useState({});

  const getDocumentIdByRoute = () => {
    if (location.pathname.includes('/docs')) {
      return location.pathname.split('/')[1];
    }
    return;
  };

  useEffect(() => {
    const id = getDocumentIdByRoute();
    const data = getDocById(id);
    const menu = data.getMenu(pages, 'docs');
    data.menu = menu;
    setData(data)
  }, [])

  return (
    <div className="blog container mx-auto px-4 mt-5 h-auto">
      <Sidebar>
        <DocMenu id={data.id} data={data.menu} img={data?.logo} title={data?.title} />
      </Sidebar>
      <main className="page">
        <div className="container mx-auto">
          <div className="theme-default-content">{children}</div>
        </div>
      </main>
    </div>
  );
};

export default DocLayout;
