import React from 'react';
import { Link } from 'gatsby';
import { removeAccents } from '../utils/text-accent-util';

import { IDocMenu, IItem } from '../models/doc-menu.model';
import './sidebar.scss';

const Sidebar = (props: any) => {
  // console.log('Sidebar menu:', JSON.stringify(props.menu));
  // console.log('Sidebar menu:', props);

  const generateSubMenu = (slug: string, items: IItem[], childs: any, isParent) => {
      return (
        <ul className="sidebar-sub-headers">
          {items?.map((item: any, index: number) => {
            const slugNew = item.slug ? item.slug : slug;
            return (
              <li key={`sub_${item.title}`} className="sidebar-sub-header">
                <Link
                  to={`/${slugNew}${isParent ? '' : removeAccents(item.url)}`}
                  // to={`/${menu.slug}`}
                  className="sidebar-link"
                  activeClassName="active"
                >
                  {item.title}
                </Link>
                {generateSubMenu(slugNew, item.items, item.childs, false)}
              </li>
            );
          })}
          {childs?.map((item: any, index: number) => {
            const slugNew = item.slug ? item.slug : slug;
            return generateSubMenu(slugNew, item.items, item.childs, false);
          })}
        </ul>
      );
  };

  return (
    <nav
      className={'sidebar-doc px-6 ' + (props.sidebarIsOpen && 'sidebar-open')}
    >
      {props.children}
      {props.img && (
        <>
          <div className="img-logo mt-4 text-center">
            <Link to={`/${props.id}`}>
              <img
                className="h-24"
                src={props.img}
                alt={props.id}
                title={props.id}
              />
            </Link>
            <p className="text-gray-700 font-bold">{props.name}</p>
          </div>
          <hr />
        </>
      )}

      <ul className="sidebar-links">
        {props.menu?.map((menu: any, i: number) =>
          generateSubMenu(menu.slug, menu.items, menu.childs, true),
        )}
      </ul>

      {/* <ul className="sidebar-links">
        {props.menu?.map((menu: any, i: number) => {
          return menu.items?.map((item: any, j: number) => (
            <li key={`menu_${item.title}`}>
              <Link
                to={`/${menu.slug}`}
                className="sidebar-link"
                activeClassName="active"
              >
                {item.title}
              </Link>
              {generateSubMenu(menu.slug, item.items, menu.childs)}
            </li>
          ))}
          )}
      </ul> */}
    </nav>
  );
};

export default Sidebar;
