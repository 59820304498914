import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import DocLayout from '../layouts/doc-layout';
import { DiscussionEmbed } from 'disqus-react';

export const query = graphql`
  query($pathSlug: String!) {
    mdx(slug: { eq: $pathSlug }) {
      slug
      frontmatter {
        title
        createDate(formatString: "DD MMMM YYYY")
        updateDate(formatString: "DD MMMM YYYY")
        path
      }
      body
      tableOfContents
    }
  }
`;


const Template = ({ data: { mdx }, location, pageContext }) => {
  // console.log('DOC TEMPLATE mdx------', mdx);
  // console.log('DOC TEMPLATE pageContext------', pageContext);
  const { slug, body, frontmatter: { title } } = mdx;
  const disqusConfig = {
    shortname: 'blucoding',
    config: { identifier: slug, title: 'test' },
  };

  console.log('slug', slug, title);
  return (
    <>
      <br />
      <DocLayout location={location} pages={pageContext.pages}>
        <h1>{title}</h1>
        <MDXRenderer>{body}</MDXRenderer>
        <br />
        <br />
        {/* <DiscussionEmbed {...disqusConfig} /> */}
      </DocLayout>
    </>
  );
};

export default Template;
